var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"breadcrumb-top"},[_c('b-breadcrumb',{},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"16"}})],1),_c('b-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$t('Configuration'))+" ")]),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'external-code' }}},[_vm._v(" "+_vm._s(_vm.$t('External Status Code'))+" ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" "+_vm._s(_vm.$t('Create Code'))+" ")])],1)],1),_c('b-button',{staticClass:"ll-cancel",attrs:{"variant":"flat-secondary","to":{ name: 'external-code' }}},[_c('b-img',{staticClass:"mr-1",attrs:{"src":require('@/assets/images/imgs/return.png'),"height":"auto"}})],1),_c('validation-observer',{ref:"accountRules"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('Base Info')))]),_c('b-form-checkbox',{staticClass:"custom-control-info ll-checkbox",model:{value:(_vm.baseInfo.isEnabled),callback:function ($$v) {_vm.$set(_vm.baseInfo, "isEnabled", $$v)},expression:"baseInfo.isEnabled"}},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")])],1),_c('b-card-body',{staticClass:"ll-cbody"},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"ll-bform",attrs:{"label":"","label-for":"internalStatusNo"}},[_c('validation-provider',{attrs:{"name":"Internal status No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Internal status No')))]),_c('b-form-input',{model:{value:(_vm.baseInfo.internalStatusNo),callback:function ($$v) {_vm.$set(_vm.baseInfo, "internalStatusNo", $$v)},expression:"baseInfo.internalStatusNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"ll-bform",attrs:{"label":"","label-for":"isEnabled"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Company')))]),_c('v-select',{attrs:{"id":"customer","options":_vm.customerList,"label":"customer","placeholder":""},on:{"input":_vm.getCustomer},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"ll-bform",attrs:{"label":"","label-for":"originStatusCode"}},[_c('validation-provider',{attrs:{"name":"Main status code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Main status code')))]),_c('b-form-input',{model:{value:(_vm.baseInfo.externalStatusCode),callback:function ($$v) {_vm.$set(_vm.baseInfo, "externalStatusCode", $$v)},expression:"baseInfo.externalStatusCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"ll-bform",attrs:{"label":_vm.$t('Sub status code'),"label-for":"originStatusMessage"}},[_c('b-form-input',{model:{value:(_vm.baseInfo.externalStatusCode2),callback:function ($$v) {_vm.$set(_vm.baseInfo, "externalStatusCode2", $$v)},expression:"baseInfo.externalStatusCode2"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"ll-bform",attrs:{"label":"","label-for":"providerCode"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ll-boldText"},[_vm._v(_vm._s(_vm.$t('Description')))]),_c('b-form-input',{model:{value:(_vm.baseInfo.originStatusMessage),callback:function ($$v) {_vm.$set(_vm.baseInfo, "originStatusMessage", $$v)},expression:"baseInfo.originStatusMessage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"ll-bform",attrs:{"label":_vm.$t('External ref No'),"label-for":"originStatusMessage"}},[_c('b-form-input',{model:{value:(_vm.baseInfo.refNo),callback:function ($$v) {_vm.$set(_vm.baseInfo, "refNo", $$v)},expression:"baseInfo.refNo"}})],1)],1)],1)],1)],1),(false)?_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title')],1),_c('b-card-body',{staticClass:"ll-cbody"})],1):_vm._e()],1),_c('div',{staticClass:"ll-subBtn"},[_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }